.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  /* ===== Colors ===== */
  .hover\:text-dark:hover,
  .text-dark {
    color: #090e34;
  }
  .hover\:text-dark-700:hover,
  .text-dark-700 {
    color: #090e34b3;
  }
  .hover\:text-primary:hover,
  .text-primary {
    color: #3056d3;
  }
  .hover\:text-secondary:hover,
  .text-secondary {
    color: #13c296;
  }
  .hover\:text-body-color:hover,
  .text-body-color {
    color: #637381;
  }
  .hover\:text-warning:hover,
  .text-warning {
    color: #f9c107;
  }
  .hover\:text-danger:hover,
  .text-danger {
    color: #dc3545;
  }
  .hover\:text-success:hover,
  .text-success {
    color: #3ca745;
  }
  .hover\:text-info:hover,
  .text-info {
    color: #3ba2b8;
  }
  .hover\:text-light:hover,
  .text-light {
    color: #efefef;
  }
  .hover\:text-form-stroke:hover,
  .text-form-stroke {
    color: #e0e0e0;
  }
  .hover\:text-tg-bg:hover,
  .text-tg-bg {
    color: #f7f8fa;
  }
  .hover\:text-black:hover,
  .text-black {
    color: #212b36;
  }
  
  /* ===== Background Colors ===== */
  .hover\:bg-dark:hover,
  .bg-dark {
    background-color: #090e34;
  }
  .hover\:bg-dark-700:hover,
  .bg-dark-700 {
    background-color: #090e34b3;
  }
  .hover\:bg-primary:hover,
  .bg-primary {
    background-color: #3056d3;
  }
  .hover\:bg-secondary:hover,
  .bg-secondary {
    background-color: #13c296;
  }
  .hover\:bg-body-color:hover,
  .bg-body-color {
    background-color: #637381;
  }
  .hover\:bg-warning:hover,
  .bg-warning {
    background-color: #f9c107;
  }
  .hover\:bg-danger:hover,
  .bg-danger {
    background-color: #dc3545;
  }
  .hover\:bg-success:hover,
  .bg-success {
    background-color: #3ca745;
  }
  .hover\:bg-info:hover,
  .bg-info {
    background-color: #3ba2b8;
  }
  .hover\:bg-light:hover,
  .bg-light {
    background-color: #efefef;
  }
  .hover\:bg-form-stroke:hover,
  .bg-form-stroke {
    background-color: #e0e0e0;
  }
  .hover\:bg-tg-bg:hover,
  .bg-tg-bg {
    background-color: #f7f8fa;
  }
  .hover\:bg-black:hover,
  .bg-black {
    background-color: #212b36;
  }
  
  /* ===== Border Colors ===== */
  .focus\:border-dark:focus,
  .border-dark {
    border-color: #090e34;
  }
  .focus\:border-dark-700:focus,
  .border-dark-700 {
    border-color: #090e34b3;
  }
  .focus\:border-primary:focus,
  .border-primary {
    border-color: #3056d3;
  }
  .focus\:border-secondary:focus,
  .border-secondary {
    border-color: #13c296;
  }
  .focus\:border-body-color:focus,
  .border-body-color {
    border-color: #637381;
  }
  .focus\:border-warning:focus,
  .border-warning {
    border-color: #f9c107;
  }
  .focus\:border-danger:focus,
  .border-danger {
    border-color: #dc3545;
  }
  .focus\:border-success:focus,
  .border-success {
    border-color: #3ca745;
  }
  .focus\:border-info:focus,
  .border-info {
    border-color: #3ba2b8;
  }
  .focus\:border-light:focus,
  .border-light {
    border-color: #efefef;
  }
  .focus\:border-form-stroke:focus,
  .border-form-stroke {
    border-color: #e0e0e0;
  }
  .focus\:border-tg-bg:focus,
  .border-tg-bg {
    border-color: #f7f8fa;
  }
  .focus\:border-black:focus,
  .border-black {
    border-color: #212b36;
  }
  
  /* ===== Box Shadows ===== */
  .hover\:shadow-input:hover,
  .shadow-input {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.03);
  }
  
  .hover\:shadow-pricing:hover,
  .shadow-pricing {
    box-shadow: 0px 39px 23px -27px rgba(0, 0, 0, 0.04);
  }
  
  .hover\:shadow-switch-1:hover,
  .shadow-switch-1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  }
  
  .hover\:shadow-testimonial-4:hover,
  .shadow-testimonial-4 {
    box-shadow: 0px 60px 120px -20px #ebeffd;
  }
  
  .hover\:shadow-testimonial-5:hover,
  .shadow-testimonial-5 {
    box-shadow: 0px 10px 39px rgba(92, 115, 160, 0.08);
  }
  
  .hover\:shadow-contact-3:hover,
  .shadow-contact-3 {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  }
  
  .hover\:shadow-contact-6:hover,
  .shadow-contact-6 {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .hover\:shadow-card:hover,
  .shadow-card {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
  
  .hover\:shadow-card-2:hover,
  .shadow-card-2 {
    box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.15);
  }
  
  /* Media Quires */
  
  @media (min-width: 540px) {
    /* ===== Colors ===== */
    .sm\:hover\:text-dark:hover,
    .sm\:text-dark {
      color: #090e34;
    }
    .sm\:hover\:text-dark-700:hover,
    .sm\:text-dark-700 {
      color: #090e34b3;
    }
    .sm\:hover\:text-primary:hover,
    .sm\:text-primary {
      color: #3056d3;
    }
    .sm\:hover\:text-secondary:hover,
    .sm\:text-secondary {
      color: #13c296;
    }
    .sm\:hover\:text-body-color:hover,
    .sm\:text-body-color {
      color: #637381;
    }
    .sm\:hover\:text-warning:hover,
    .sm\:text-warning {
      color: #f9c107;
    }
    .sm\:hover\:text-danger:hover,
    .sm\:text-danger {
      color: #dc3545;
    }
    .sm\:hover\:text-success:hover,
    .sm\:text-success {
      color: #3ca745;
    }
    .sm\:hover\:text-info:hover,
    .sm\:text-info {
      color: #3ba2b8;
    }
    .sm\:hover\:text-light:hover,
    .sm\:text-light {
      color: #efefef;
    }
    .sm\:hover\:text-form-stroke:hover,
    .sm\:text-form-stroke {
      color: #e0e0e0;
    }
    .sm\:hover\:text-tg-bg:hover,
    .sm\:text-tg-bg {
      color: #f7f8fa;
    }
    .sm\:hover\:text-black:hover,
    .sm\:text-black {
      color: #212b36;
    }
  
    /* ===== Background Colors ===== */
    .sm\:hover\:bg-dark:hover,
    .sm\:bg-dark {
      background-color: #090e34;
    }
    .sm\:hover\:bg-dark-700:hover,
    .sm\:bg-dark-700 {
      background-color: #090e34b3;
    }
    .sm\:hover\:bg-primary:hover,
    .sm\:bg-primary {
      background-color: #3056d3;
    }
    .sm\:hover\:bg-secondary:hover,
    .sm\:bg-secondary {
      background-color: #13c296;
    }
    .sm\:hover\:bg-body-color:hover,
    .sm\:bg-body-color {
      background-color: #637381;
    }
    .sm\:hover\:bg-warning:hover,
    .sm\:bg-warning {
      background-color: #f9c107;
    }
    .sm\:hover\:bg-danger:hover,
    .sm\:bg-danger {
      background-color: #dc3545;
    }
    .sm\:hover\:bg-success:hover,
    .sm\:bg-success {
      background-color: #3ca745;
    }
    .sm\:hover\:bg-info:hover,
    .sm\:bg-info {
      background-color: #3ba2b8;
    }
    .sm\:hover\:bg-light:hover,
    .sm\:bg-light {
      background-color: #efefef;
    }
    .sm\:hover\:bg-form-stroke:hover,
    .sm\:bg-form-stroke {
      background-color: #e0e0e0;
    }
    .sm\:hover\:bg-tg-bg:hover,
    .sm\:bg-tg-bg {
      background-color: #f7f8fa;
    }
    .sm\:hover\:bg-black:hover,
    .sm\:bg-black {
      background-color: #212b36;
    }
  }
  
  @media (min-width: 720px) {
    /* ===== Colors ===== */
    .md\:hover\:text-dark:hover,
    .md\:text-dark {
      color: #090e34;
    }
    .md\:hover\:text-dark-700:hover,
    .md\:text-dark-700 {
      color: #090e34b3;
    }
    .md\:hover\:text-primary:hover,
    .md\:text-primary {
      color: #3056d3;
    }
    .md\:hover\:text-secondary:hover,
    .md\:text-secondary {
      color: #13c296;
    }
    .md\:hover\:text-body-color:hover,
    .md\:text-body-color {
      color: #637381;
    }
    .md\:hover\:text-warning:hover,
    .md\:text-warning {
      color: #f9c107;
    }
    .md\:hover\:text-danger:hover,
    .md\:text-danger {
      color: #dc3545;
    }
    .md\:hover\:text-success:hover,
    .md\:text-success {
      color: #3ca745;
    }
    .md\:hover\:text-info:hover,
    .md\:text-info {
      color: #3ba2b8;
    }
    .md\:hover\:text-light:hover,
    .md\:text-light {
      color: #efefef;
    }
    .md\:hover\:text-form-stroke:hover,
    .md\:text-form-stroke {
      color: #e0e0e0;
    }
    .md\:hover\:text-tg-bg:hover,
    .md\:text-tg-bg {
      color: #f7f8fa;
    }
    .md\:hover\:text-black:hover,
    .md\:text-black {
      color: #212b36;
    }
  
    /* ===== Background Colors ===== */
    .md\:hover\:bg-dark:hover,
    .md\:bg-dark {
      background-color: #090e34;
    }
    .md\:hover\:bg-dark-700:hover,
    .md\:bg-dark-700 {
      background-color: #090e34b3;
    }
    .md\:hover\:bg-primary:hover,
    .md\:bg-primary {
      background-color: #3056d3;
    }
    .md\:hover\:bg-secondary:hover,
    .md\:bg-secondary {
      background-color: #13c296;
    }
    .md\:hover\:bg-body-color:hover,
    .md\:bg-body-color {
      background-color: #637381;
    }
    .md\:hover\:bg-warning:hover,
    .md\:bg-warning {
      background-color: #f9c107;
    }
    .md\:hover\:bg-danger:hover,
    .md\:bg-danger {
      background-color: #dc3545;
    }
    .md\:hover\:bg-success:hover,
    .md\:bg-success {
      background-color: #3ca745;
    }
    .md\:hover\:bg-info:hover,
    .md\:bg-info {
      background-color: #3ba2b8;
    }
    .md\:hover\:bg-light:hover,
    .md\:bg-light {
      background-color: #efefef;
    }
    .md\:hover\:bg-form-stroke:hover,
    .md\:bg-form-stroke {
      background-color: #e0e0e0;
    }
    .md\:hover\:bg-tg-bg:hover,
    .md\:bg-tg-bg {
      background-color: #f7f8fa;
    }
    .md\:hover\:bg-black:hover,
    .md\:bg-black {
      background-color: #212b36;
    }
  }
  
  @media (min-width: 960px) {
    /* ===== Colors ===== */
    .lg\:hover\:text-dark:hover,
    .lg\:text-dark {
      color: #090e34;
    }
    .lg\:hover\:text-dark-700:hover,
    .lg\:text-dark-700 {
      color: #090e34b3;
    }
    .lg\:hover\:text-primary:hover,
    .lg\:text-primary {
      color: #3056d3;
    }
    .lg\:hover\:text-secondary:hover,
    .lg\:text-secondary {
      color: #13c296;
    }
    .lg\:hover\:text-body-color:hover,
    .lg\:text-body-color {
      color: #637381;
    }
    .lg\:hover\:text-warning:hover,
    .lg\:text-warning {
      color: #f9c107;
    }
    .lg\:hover\:text-danger:hover,
    .lg\:text-danger {
      color: #dc3545;
    }
    .lg\:hover\:text-success:hover,
    .lg\:text-success {
      color: #3ca745;
    }
    .lg\:hover\:text-info:hover,
    .lg\:text-info {
      color: #3ba2b8;
    }
    .lg\:hover\:text-light:hover,
    .lg\:text-light {
      color: #efefef;
    }
    .lg\:hover\:text-form-stroke:hover,
    .lg\:text-form-stroke {
      color: #e0e0e0;
    }
    .lg\:hover\:text-tg-bg:hover,
    .lg\:text-tg-bg {
      color: #f7f8fa;
    }
    .lg\:hover\:text-black:hover,
    .lg\:text-black {
      color: #212b36;
    }
  
    /* ===== Background Colors ===== */
    .lg\:hover\:bg-dark:hover,
    .lg\:bg-dark {
      background-color: #090e34;
    }
    .lg\:hover\:bg-dark-700:hover,
    .lg\:bg-dark-700 {
      background-color: #090e34b3;
    }
    .lg\:hover\:bg-primary:hover,
    .lg\:bg-primary {
      background-color: #3056d3;
    }
    .lg\:hover\:bg-secondary:hover,
    .lg\:bg-secondary {
      background-color: #13c296;
    }
    .lg\:hover\:bg-body-color:hover,
    .lg\:bg-body-color {
      background-color: #637381;
    }
    .lg\:hover\:bg-warning:hover,
    .lg\:bg-warning {
      background-color: #f9c107;
    }
    .lg\:hover\:bg-danger:hover,
    .lg\:bg-danger {
      background-color: #dc3545;
    }
    .lg\:hover\:bg-success:hover,
    .lg\:bg-success {
      background-color: #3ca745;
    }
    .lg\:hover\:bg-info:hover,
    .lg\:bg-info {
      background-color: #3ba2b8;
    }
    .lg\:hover\:bg-light:hover,
    .lg\:bg-light {
      background-color: #efefef;
    }
    .lg\:hover\:bg-form-stroke:hover,
    .lg\:bg-form-stroke {
      background-color: #e0e0e0;
    }
    .lg\:hover\:bg-tg-bg:hover,
    .lg\:bg-tg-bg {
      background-color: #f7f8fa;
    }
    .lg\:hover\:bg-black:hover,
    .lg\:bg-black {
      background-color: #212b36;
    }
  }
  
  /* Ecommerce css */
  
  input[type="checkbox"]:checked ~ .box span {
    opacity: 100%;
  }
  
  input[type="radio"]:checked ~ .box .circle {
    background: #3056d3;
  }
  
  input[type="radio"]:checked ~ .box span {
    opacity: 100%;
  }
  
  .payment:checked ~ label {
    border-color: #3056d3;
    background: rgba(48, 86, 211, 0.08);
  }
  
  .shipping:checked ~ label {
    border-color: #3056d3;
  }
  
  .shipping:checked ~ label .title {
    color: #3056d3;
  }
  
  .color:checked ~ label span {
    height: 8px;
    width: 8px;
  }
  
  .productColor:checked ~ label span {
    height: 28px;
    width: 28px;
  }
  
  .productColor2:checked ~ label span {
    height: 12px;
    width: 12px;
  }
  
  .filter-size:checked ~ label {
    border-color: #3056d3;
    background: #3056d3;
    color: #fff;
  }
  
  .filter-size-2:checked ~ label {
    border-color: #3056d3;
    background: rgba(48, 86, 211, 0.1);
  }
  
  .ram-size:checked ~ label {
    border-color: #3056d3;
    color: #3056d3;
  }
  
  .download-radio:checked ~ label {
    border-color: #3056d3;
    background: #3056d3;
  }
  .download-radio:checked ~ label span {
    color: #fff;
  }
  .download-radio:checked ~ label .icon {
    opacity: 100%;
  }
  
  /* ============
  pricing slider
  =============== */
  
  .priceSlideOne .noUi-target {
    margin-top: 32px;
    border: none;
    background: #f4f7ff;
    box-shadow: none;
  }
  
  .priceSlideOne .noUi-connects {
    height: 6px;
    border-radius: 50%;
    background: #d4d9e8;
  }
  
  .priceSlideOne .noUi-connect {
    height: 6px;
    border-radius: 50%;
    background: #3056d3;
  }
  
  .priceSlideOne .noUi-horizontal .noUi-handle {
    top: -8px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 6px;
    border-color: #3056d3;
    background: #fff;
  }
  
  .priceSlideTwo .noUi-target {
    margin-top: 32px;
    border: none;
    background: #fff;
    box-shadow: none;
  }
  
  .priceSlideTwo .noUi-connects {
    height: 4px;
    border-radius: 50%;
    background: #d4d9e8;
  }
  
  .priceSlideTwo .noUi-connect {
    height: 4px;
    border-radius: 50%;
    background: #3056d3;
  }
  
  .priceSlideTwo .noUi-horizontal .noUi-handle {
    top: -12px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #3056d3;
    background: #fff;
  }
  
  .noUi-horizontal .noUi-handle:after,
  .noUi-horizontal .noUi-handle:before {
    display: none;
  }